import { Box, Grid, Typography } from '@mui/material'
import { SectionHeader } from 'components/SectionHeader'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useState } from 'react'
import { UploadFile } from './UploadFile'
import { useGetSettingEULA } from 'hooks/api/Settings/useGetSettingEULA'
import { SettingsDetailsHeader } from './SettingsDetailsHeader'
import { formatDateTime } from 'utils/util'

export const SettingsDetailsPageEULA = () => {
  const [isFileModalOpen, setIsFileModalOpen] = useState(false)

  const toggleFileModalIsOpen = async () => {
    setIsFileModalOpen(!isFileModalOpen)
  }

  const settingsEULAQuery = useGetSettingEULA()

  if (settingsEULAQuery.isPending) {
    return (
      <SettingsDetailsHeader currentTab="/Settings/SettingsDetailsPageEULA">
        <ActivityIndicator />
      </SettingsDetailsHeader>
    )
  }

  const settingEULA = settingsEULAQuery.data

  return (
    <SettingsDetailsHeader currentTab="/Settings/SettingsDetailsPageEULA">
      <Box className="gap-12 flex flex-col">
        <Box>
          <SectionHeader
            title="EULA"
            onClickButton={toggleFileModalIsOpen}
            buttonText="Upload EULA"
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              rowGap: '16px',
              borderWidth: 1,
              borderColor: 'grey.300',
              borderRadius: 2,
              px: '16px',
              py: '16px',
              mt: '12px',
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ pt: { xs: '24px', sm: 0 } }}
              >
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    FileName:
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {settingEULA?.fileName}
                  </Typography>

                  <Typography variant="body2" pb={1} color="text.secondary">
                    Last Modified:
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {formatDateTime(settingEULA?.lastModified, 'P p')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <UploadFile
            isModalOpen={isFileModalOpen}
            toggleModalIsOpen={toggleFileModalIsOpen}
          />
        </Box>
      </Box>
    </SettingsDetailsHeader>
  )
}
