import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useApiClient } from 'hooks/useApiClient'
import { type RewardsImportPoints } from 'types/api'
import { type FileRequest } from 'types/fileUpload'

interface usePostImportPointsProps {
  id: number
  onSuccess?: (data: RewardsImportPoints, variables: FileRequest) => void
  onError?: (error: Error, variables: FileRequest) => void
}

export const usePostImportPoints = ({
  id,
  onSuccess,
  onError,
}: usePostImportPointsProps) => {
  const postAPIClient = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (importPoints: FileRequest) => {
      const body = new FormData()
      body.set('FileUpload', importPoints.File)
      body.set('FileUploadName', importPoints.FileName)
      body.set('FileUploadType', importPoints.FileType)

      const { data, error } = await postAPIClient.post(
        '/admin/rewards-programs/{id}/importpoints',
        {
          params: { path: { id } },
          body: {},
          bodySerializer: () => body,
        }
      )
      return { data, error }
    },
    onSuccess: async ({ data, error }, variables: FileRequest) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/rewards-programs/{id}/balances', id],
      })
      if (error !== undefined) {
        onError?.(error as Error, variables)
      } else {
        onSuccess?.(data as unknown as RewardsImportPoints, variables)
      }
    },
    onError,
  })
}
