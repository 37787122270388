import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { isNil } from 'lodash'
import { isIdPresent } from 'utils/typeUtils'
import { type RequiredId } from 'utils/typeUtils'
import { type Newsletter } from 'types/api'
import { useGetNewsletterById } from 'hooks/api/Newsletter/useGetNewsletterById'
import { SectionHeader } from 'components/SectionHeader'
import { useState } from 'react'
import { usePutNewsletter } from 'hooks/api/Newsletter/usePutNewsletter'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'stores/useSnackbar'
import { formatDateTime } from 'utils/util'
import { EditNewsletterDetails } from './EditNewsletterDetails'

export interface EditNewsletterDetailsProps {
  newsletter: RequiredId<Newsletter>
  onSubmitNewsletter: (newsletter: RequiredId<Newsletter>) => void
  toggleModalIsOpen: () => void
  updateIsActive: boolean
  isModalOpen: boolean
}

export const NewslettersDetailsSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModalIsOpen = () => setIsModalOpen(!isModalOpen)
  const id = Number(useParams().id)
  const getNewsletterQuery = useGetNewsletterById(id)
  const queryClient = useQueryClient()
  const setMessage = useSnackbar((state) => state.setMessage)

  const putNewsletterMutation = usePutNewsletter({
    newsletterId: id,
    onSuccess: () => {
      setMessage('Newsletter has been updated.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      void queryClient.invalidateQueries({
        queryKey: ['/admin/newsletters/{id}', id],
      })
      toggleModalIsOpen()
    },
    onError: () => {
      setMessage('Error updating Newsletter', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  if (getNewsletterQuery.isPending) {
    return <CircularProgress />
  }

  if (getNewsletterQuery.isError) {
    return <p>An error occurred.</p>
  }

  const newsletter = getNewsletterQuery.data

  if (isNil(newsletter) || !isIdPresent(newsletter)) {
    return <p>An error occurred.</p>
  }

  return (
    <>
      <SectionHeader
        title="Newsletter Overview"
        onClickButton={toggleModalIsOpen}
      />
      <Box
        sx={{
          borderWidth: 1,
          borderColor: 'grey.300',
          borderRadius: 2,
          p: '16px',
          mb: '40px',
        }}
      >
        <Grid container sx={{ rowGap: 5 }}>
          <Grid item md={4}>
            <Typography variant="body-2" color="text.secondary">
              Title
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {newsletter.title}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body-2" color="text.secondary">
              Date
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {formatDateTime(newsletter.createdOn, 'P p')}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body-2" color="text.secondary">
              Status
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {newsletter.active ? 'Active' : 'Inactive'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <EditNewsletterDetails
        newsletter={newsletter}
        updateIsActive={putNewsletterMutation.isPending}
        isModalOpen={isModalOpen}
        onSubmitNewsletter={(newsletter) => {
          putNewsletterMutation.mutate(newsletter)
        }}
        toggleModalIsOpen={toggleModalIsOpen}
      />
    </>
  )
}
