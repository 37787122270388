import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { JJUpdatesPageTabs } from './JJUpdatesPageTabs'

export const JJUpdatesHeader = ({
  currentTab,
  children,
}: {
  currentTab: string
  children?: React.ReactNode
}) => {
  return (
    <Page
      header={
        <PageHeader
          title="J&J Updates"
          isSecondary={true}
          tabs={<JJUpdatesPageTabs currentTab={currentTab} />}
        />
      }
    >
      {children}
    </Page>
  )
}
