import { SelectLicensedEstablishment } from 'components/SelectLicensedEstablishment'
import { type InferType } from 'yup'
import { type CampaignSchema } from './CampaignSchema'
import { useFormContext } from 'react-hook-form'
import {
  Box,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { SelectOrganization } from 'components/SelectOrganization'
import { useEffect } from 'react'
import { SelectCorporateAccount } from 'components/SelectCorporateAccount'

export const ExcludedOptionsSelect = () => {
  const { register, watch, unregister } = useFormContext()
  const excludeType = watch('excludeType')

  useEffect(() => {
    unregister('excludedId')
  }, [excludeType])

  return (
    <>
      <Box>
        <InputLabel htmlFor={'excludeNone'}>Exclude Patrons by</InputLabel>

        <RadioGroup defaultValue={'None'}>
          <FormControlLabel
            key={'none'}
            label={'None'}
            control={
              <Radio
                {...register('excludeType')}
                value={'None'}
                id={`excludeNone`}
              />
            }
          />
          <FormControlLabel
            key={'excludePatronsWithCheckins'}
            label={'Any Patron with a Check-in'}
            control={
              <Radio
                {...register('excludeType')}
                value={'excludePatronsWithCheckins'}
                id={`excludePatronsWithCheckins`}
              />
            }
          />
          <FormControlLabel
            key={'le'}
            label={'Licensed Establishment'}
            control={
              <Radio
                {...register('excludeType')}
                value={'LE'}
                id={`excludeLE`}
              />
            }
          />
          <FormControlLabel
            key={'org'}
            label={'Organization'}
            control={
              <Radio
                {...register('excludeType')}
                value={'Org'}
                id={`excludeOrg`}
              />
            }
          />
          <FormControlLabel
            key={'ca'}
            label={'Corporate Account'}
            control={
              <Radio
                {...register('excludeType')}
                value={'CA'}
                id={`excludeCA`}
              />
            }
          />
        </RadioGroup>
      </Box>
      {excludeType === 'LE' && (
        <SelectLicensedEstablishment<InferType<typeof CampaignSchema>>
          label="Excluded Location"
          required
          fullWidth
          hideCard
          licensedEstablishmentIdKey="excludedId"
          {...register('excludedId')}
        />
      )}
      {excludeType === 'Org' && (
        <SelectOrganization
          label="Excluded Organization"
          required
          fullWidth
          corporateAccountId={undefined}
          showAll={true}
          {...register('excludedId')}
        />
      )}
      {excludeType === 'CA' && (
        <SelectCorporateAccount
          {...register('excludedId')}
          label="Excluded Corporate Account"
          required
          fullWidth
          corporateAccountIdKey="corporateAccountId"
          placeholder="Select a corporate account"
        />
      )}
    </>
  )
}
