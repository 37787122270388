import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLayout } from 'hooks/useLayout'
import { Modal } from 'components/Modal/Modal'
import { useSnackbar } from 'stores/useSnackbar'
import { type StateFileUpload } from 'types/fileUpload'
import { Box, Button } from '@mui/material'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { UploaderDisplayer } from 'components/FileUploader/UploaderDisplayer'
import { ActivityButton } from 'components/ActivityButton'
import { usePostImportPoints } from 'hooks/api/RewardProgram/usePostImportPoints'

export const ImportsPoints = ({
  rewardId,
  isModalOpen,
  toggleModalIsOpen,
}: {
  rewardId: number
  isModalOpen: boolean
  toggleModalIsOpen: () => void
}) => {
  const { isMobile } = useLayout()
  const { handleSubmit } = useForm()
  const [stateFilesUpload, setStateFilesUpload] = useState<StateFileUpload>(0)
  const [messageError, setMessageError] = useState('')
  const [showFiles, setShowFiles] = useState(false)
  const [files, setFiles] = useState<File[]>([])
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const toggleShowFiles = () => setShowFiles(!showFiles)

  const resetAll = () => {
    toggleModalIsOpen()
    setShowFiles(false)
    setFiles([])
    setMessageError('')
  }

  const addFiles = (files: File[]) => {
    setFiles(files)
    toggleShowFiles()
  }

  const removeFile = (file: File) => {
    setFiles(
      files?.filter((fileMatch) => {
        return fileMatch.name !== file.name
      })
    )
    setStateFilesUpload(0)
    toggleShowFiles()
  }

  const toggleStateFilesUpload = (value: StateFileUpload) => {
    setMessageError('')
    if (stateFilesUpload === 0) {
      setStateFilesUpload(value)
    }
    if (value === 2) {
      setMessageError('Please correct errors to continue')
    }
  }

  const { mutate: putImportPointsMutation, isPending: isUploadActive } =
    usePostImportPoints({
      id: Number(rewardId),
      onSuccess: () => {
        resetAll()
        setSnackbarMessage(`File has been imported`)
      },
      onError: (error, _) => {
        console.log(error)
        setMessageError(error.message)
      },
    })

  const updateRewardWrapper = handleSubmit(async () => {
    if (stateFilesUpload !== 1) {
      setMessageError('Please correct errors to continue')
      return
    }

    putImportPointsMutation({
      FileName: files[0].name,
      File: files[0],
      FileType: 'csv',
    })
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '60%',
        height: isMobile ? '100%' : '75%',
      }}
    >
      <Box>
        <form onSubmit={updateRewardWrapper}>
          <ModalFullHeader
            toggleIsOpen={toggleModalIsOpen}
            title="Import Points"
          />
          <ModalFullBody heightModal={isMobile ? '100%' : '75%'}>
            {messageError && (
              <Box className="w-full mb-[3%]">
                <FormFieldErrorMessage
                  message={messageError}
                  variant="contained"
                />
              </Box>
            )}
            <UploaderDisplayer
              files={files}
              addFiles={addFiles}
              removeFile={removeFile}
              toggleStateFilesUpload={toggleStateFilesUpload}
              showImages={showFiles}
              textToShowButton="Click to import."
              informationFileUpload="Select a CSV file that contains columns of Patron ID, Patron First Name, Patron Last Name, Points"
              maxSizeAllowedUpload={500}
              fileFormatsAllowedUpload="*.csv"
            />
          </ModalFullBody>
          <ModalFullFooter>
            <Button onClick={resetAll}>Cancel</Button>
            <ActivityButton
              type="submit"
              variant="contained"
              active={isUploadActive}
            >
              Import
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </Box>
    </Modal>
  )
}
