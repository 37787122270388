import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface UseDeleteNewsletterParams {
  id: number | null | undefined
}

interface UseDeleteNewsletterProps {
  onSuccess?: (data: undefined, variables: UseDeleteNewsletterParams) => void
  onError?: (error: Error, variables: UseDeleteNewsletterParams) => void
}

export const useDeleteNewsletterById = ({
  onSuccess,
  onError,
}: UseDeleteNewsletterProps) => {
  const deleteNewsletter = useTypedApiClient({
    path: '/admin/newsletters/{id}',
    method: 'delete',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, UseDeleteNewsletterParams>({
    mutationFn: async ({ id }: UseDeleteNewsletterParams) => {
      await deleteNewsletter({ id: Number(id) })
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/newsletters'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
