import { Box, Button } from '@mui/material'
import { SectionHeader } from 'components/SectionHeader'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useState } from 'react'
import { SimpleImageCard } from 'components/ImageCard/SimpleImageCard'
import { UploadPrizeFiles } from './UploadPrizeFiles'
import { useGetPrizeImage } from 'hooks/api/Settings/useGetPrizeImage'
import { SettingsDetailsHeader } from './SettingsDetailsHeader'

export const SettingsDetailsPatronApp = () => {
  const [isFileModalOpen, setIsFileModalOpen] = useState(false)

  const toggleFileModalIsOpen = async () => {
    setIsFileModalOpen(!isFileModalOpen)
  }

  const prizeImageQuery = useGetPrizeImage()

  if (prizeImageQuery.isPending) {
    return <ActivityIndicator />
  }

  const prizeImageUrl = prizeImageQuery.data

  return (
    <SettingsDetailsHeader currentTab="/Settings/SettingsDetailsPagePatronApp">
      <Box className="gap-12 flex flex-col">
        <Box>
          <SectionHeader title="Patron App Settings" showButton={false} />

          <Box className="pb-3">Current Prize Image & Animation:</Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <SimpleImageCard
              imageSource={prizeImageUrl}
              sx={{ width: '150px' }}
            />
            <Button onClick={toggleFileModalIsOpen} variant="contained">
              Upload New Image & Animation
            </Button>
          </Box>
          <UploadPrizeFiles
            isModalOpen={isFileModalOpen}
            toggleModalIsOpen={toggleFileModalIsOpen}
          />
        </Box>
      </Box>
    </SettingsDetailsHeader>
  )
}
