import { useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import type {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid'
import { Link, useNavigate } from 'react-router-dom'
import { isNil } from 'lodash'
import { type Newsletter } from 'types/api'
import { dataTablePromotionDateFormatter } from 'utils/util'
import { Colors } from 'components/Theme'
import { DataTable } from 'components/Shared/DataTable'
import { GridSearchField } from 'components/GridSearchField/GridSearchField'
import { useGetNewsletters } from 'hooks/api/Newsletter/useGetNewsletters'
import { DeleteNewsletterRenderCell } from './DeleteNewsletter/DeleteNewsletterRenderCell'
import { DeleteNewsletterModal } from './DeleteNewsletter/DeleteNewsletterModal'

export const JJUpdatesNewslettersPage = () => {
  const [filteredData, setFilteredData] = useState<Newsletter[]>()
  const [newsletterRowData, setNewsletterRowData] =
    useState<Newsletter | null>()
  const navigate = useNavigate()
  const useGetNewslettersQuery = useGetNewsletters()

  const handleFilteredData = (filteredData: Newsletter[]) => {
    setFilteredData(filteredData)
  }

  const selectCell: GridEventListener<'cellClick'> = (params) => {
    if (params.field !== '__menu__' && params.id !== null) {
      navigate(`/JJUpdates/Newsletters/${params.id}`)
    }
  }

  const getColumns = (): GridColDef[] => {
    return [
      {
        field: 'title',
        minWidth: 300,
        headerName: 'Title',
        flex: 2,
      },
      {
        field: 'createdOn',
        minWidth: 50,
        headerName: 'Date',
        flex: 1,
        valueFormatter: dataTablePromotionDateFormatter(false, 'Start'),
      },
      {
        field: 'active',
        minWidth: 100,
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => (
          <Box
            className="p-2 py-1 rounded-sm"
            bgcolor={
              params.row.active
                ? Colors.licensedEstablishmentStatus.background.active
                : Colors.licensedEstablishmentStatus.background.inactive
            }
            color={
              params.row.active
                ? Colors.licensedEstablishmentStatus.text.active
                : Colors.licensedEstablishmentStatus.text.inactive
            }
          >
            <Typography
              sx={{ textTransform: 'uppercase' }}
              variant="body-1"
              fontWeight={'bold'}
            >
              {params.row.active ? 'ACTIVE' : 'INACTIVE'}
            </Typography>
          </Box>
        ),
      },
      {
        field: '__menu__',
        headerName: '',
        align: 'left',
        renderCell: (params: GridRenderCellParams<Newsletter>) => {
          return (
            <DeleteNewsletterRenderCell
              params={params}
              setNewsletterRowData={setNewsletterRowData}
            />
          )
        },
      },
    ]
  }

  return (
    <>
      <Stack
        direction={{ md: 'row', sm: 'column', xs: 'column' }}
        gap={{ md: 0, sm: 2, xs: 2 }}
        mb={{ md: 4, sm: 2, xs: 2 }}
        justifyContent={'space-between'}
      >
        <Stack width={'50%'}>
          <GridSearchField<Newsletter>
            placeholder="Search Newsletters"
            data={useGetNewslettersQuery.data ?? []}
            handleFilteredData={handleFilteredData}
            fullWidth
          />
        </Stack>
        <Link to="Newsletters/New">
          <Button variant="contained">Create New Newsletter</Button>
        </Link>
      </Stack>
      <DataTable
        data-testid="data-table"
        sort="desc"
        sortFieldName={'createdOn'}
        rowHeight={65}
        columns={getColumns()}
        rows={filteredData ?? useGetNewslettersQuery.data ?? []}
        onCellClick={selectCell}
      />
      <DeleteNewsletterModal
        data={newsletterRowData}
        isModalOpen={!isNil(newsletterRowData)}
        toggleIsOpen={() => setNewsletterRowData(undefined)}
      />
    </>
  )
}
