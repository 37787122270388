import { Typography, Box, useTheme, Stack } from '@mui/material'
import { StatusChip } from 'components/StatusChip/StatusChip'
import { type FullCheckInPolicy } from 'types/api'

interface BonusPointDetailsProps {
  checkInPolicy: FullCheckInPolicy
}

export const WelcomeBonusPointDetails = ({
  checkInPolicy,
}: BonusPointDetailsProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        border: 1,
        borderColor: theme.palette.grey[200],
        mt: '16px',
        gap: '16px',
        p: '24px',
      }}
    >
      {checkInPolicy?.enablePatronWelcomeBonus ? (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: { xs: 'column' },
            gap: '16px',
          }}
        >
          <Stack spacing={3}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Typography variant="h4" marginRight={2}>
                Welcome Bonus
              </Typography>
              <StatusChip
                active={!!checkInPolicy?.enablePatronWelcomeBonus}
                sx={{ maxWidth: '100px' }}
              />
            </Box>
            <Box display={'flex'} flexDirection={'row'}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" pb={1} color="text.secondary">
                  The user will receive{' '}
                  <b>{checkInPolicy?.patronWelcomeBonus}</b> extra points upon
                  registering for the app.
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ flex: 1 }}>
          <Typography variant="body1" pb={1}>
            Edit to make active
          </Typography>
        </Box>
      )}
    </Box>
  )
}
