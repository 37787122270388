import { type Newsletter } from 'types/api'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useApiClient } from 'hooks/useApiClient'
import { type FileImageRequest } from 'types/fileUpload'

interface UsePutFileProps {
  id: number
  onSuccess?: (data: Newsletter, variables: FileImageRequest) => void
  onError?: (error: Error, variables: FileImageRequest) => void
}

export const usePutFileNewsletter = ({
  id,
  onSuccess,
  onError,
}: UsePutFileProps) => {
  const putAPIClient = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (newsletter: FileImageRequest) => {
      const body = new FormData()
      body.set('FileUpload', newsletter.ImageFile)
      body.set('FileUploadName', newsletter.ImageFileName)
      body.set('FileUploadType', newsletter.ImageFileType)

      const { data, error } = await putAPIClient.put(
        '/admin/newsletters/{id}/uploadfile',
        {
          params: { path: { id } },
          body: {},
          bodySerializer: () => body,
        }
      )
      return { data, error }
    },
    onSuccess: async ({ data, error }, variables: FileImageRequest) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/newsletters', id],
      })
      if (error !== undefined) {
        onError?.(error as Error, variables)
      } else {
        onSuccess?.(data as Newsletter, variables)
      }
    },
    onError,
  })
}
