import { Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'

export const JJUpdatesPageTabs = ({ currentTab }: { currentTab: any }) => {
  return (
    <Tabs value={currentTab}>
      <Tab
        label="Newsletters"
        value={`/JJUpdates/Newsletters/JJUpdatesNewslettersPage`}
        to={`/JJUpdates`}
        component={Link}
      />
    </Tabs>
  )
}
