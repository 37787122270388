import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { ActivityButton } from 'components/ActivityButton'
import { useDeleteNewsletterById } from 'hooks/api/Newsletter/useDeleteNewsletterById'
import { useSnackbar } from 'stores/useSnackbar'
import { type Newsletter } from 'types/api'
import { formatDateTime } from 'utils/util'

interface DeleteNewsletterModalProps {
  isModalOpen: boolean
  data?: Newsletter | null
  toggleIsOpen: () => void
}

export const DeleteNewsletterModal = ({
  isModalOpen,
  toggleIsOpen,
  data,
}: DeleteNewsletterModalProps) => {
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const useDeleteNewsletterMutation = useDeleteNewsletterById({
    onSuccess: () => {
      setSnackbarMessage(`Newsletter successfully deleted`, 'success')
      toggleIsOpen()
    },
    onError: () => {
      setSnackbarMessage(
        `An error has occured while deleting the newsletter`,
        'error'
      )
      toggleIsOpen()
    },
  })

  const handleDelete = (id?: number | null) => {
    useDeleteNewsletterMutation.mutate({ id })
  }

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '900px' } }}
      onClose={toggleIsOpen}
    >
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
        <Box px={4} py={4}>
          <Typography variant="h3" fontWeight={'bold'}>
            Delete Newsletter
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 4, px: 4 }}>
        <Box paddingBottom={2} paddingTop={3}>
          <Typography variant="h4" fontSize={18} paddingBottom={2}>
            Are you sure you want to delete this newsletter?
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <InputLabel
              sx={{ fontSize: 16, paddingTop: 2, fontWeight: 'normal' }}
            >
              Title
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              value={data?.title}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <InputLabel
              sx={{ fontSize: 16, paddingTop: 2, fontWeight: 'normal' }}
            >
              Date
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              value={formatDateTime(data?.createdOn)}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ borderTop: `1px solid ${grey[300]}`, pt: 2, pb: 2, px: 4 }}
      >
        <Button variant="text" onClick={toggleIsOpen}>
          Cancel
        </Button>
        <ActivityButton
          variant="contained"
          onClick={() => handleDelete(data?.id)}
          active={useDeleteNewsletterMutation.isPending}
        >
          Delete Newsletter
        </ActivityButton>
      </DialogActions>
    </Dialog>
  )
}
