import { Box, Typography } from '@mui/material'
import type { components } from 'api/playerPayback/api'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { isNil } from 'lodash'
import { getLastUpdated } from 'utils/util'
import { Colors } from 'components/Theme'

export const NewslettersDetailsHeader = ({
  newsletter,
  actionButton,
  children,
}: {
  newsletter: components['schemas']['FullNewsletterDTO']
  actionButton?: React.ReactNode
  children?: React.ReactNode
}) => {
  if (!newsletter.id) {
    return <Typography>Newsletter Id must not be null.</Typography>
  }

  return (
    <Page
      header={
        <PageHeader
          title={isNil(newsletter.title) ? 'Unknown' : newsletter.title}
          subtitle={getLastUpdated(newsletter.modifiedOn)}
          backText="Newsletters"
          backPath="/JJUpdates"
          isSecondary={true}
          status={
            <Box className="flex items-center">
              <Box
                className="p-2 py-1 rounded-sm"
                bgcolor={
                  newsletter.active
                    ? Colors.licensedEstablishmentStatus.background.active
                    : Colors.licensedEstablishmentStatus.background.inactive
                }
                color={
                  newsletter.active
                    ? Colors.licensedEstablishmentStatus.text.active
                    : Colors.licensedEstablishmentStatus.text.inactive
                }
              >
                <Typography
                  sx={{ textTransform: 'uppercase' }}
                  variant="body-1"
                  fontWeight={'bold'}
                >
                  {newsletter.active ? 'ACTIVE' : 'INACTIVE'}
                </Typography>
              </Box>
            </Box>
          }
          actionButton={actionButton}
        />
      }
    >
      {children}
    </Page>
  )
}
