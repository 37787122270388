import { JJUpdatesHeader } from './JJUpdatesHeader'
import { JJUpdatesNewslettersPage } from './Newsletters/JJUpdatesNewslettersPage'

export const JJUpdatesPage = () => {
  return (
    <JJUpdatesHeader
      currentTab={'/JJUpdates/Newsletters/JJUpdatesNewslettersPage'}
    >
      <JJUpdatesNewslettersPage />
    </JJUpdatesHeader>
  )
}
