import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Stack } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { Page } from 'components/Shared/Page'
import { useApiClient } from 'hooks/useApiClient'
import { useForm, type Control } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'stores/useSnackbar'
import { object, string } from 'yup'
import { PageHeader } from 'components/Shared/PageHeader'
import { TextField } from 'components/Shared/TextField'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'

const today = new Date()
today.setHours(0, 0, 0, 0)

const newsletterSchema = object().shape({
  title: string().required('Must enter a title'),
})

type FormSchemaType<T> = T extends Control<infer R> ? R : never

export const NewslettersNewPage = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(newsletterSchema),
  })
  const { post } = useApiClient()
  const setMessage = useSnackbar((state) => state.setMessage)
  const navigate = useNavigate()

  const createNewsletter = useMutation({
    mutationKey: ['newsletters/create'],
    mutationFn: async (data: FormSchemaType<typeof control>) => {
      const response = await post('/admin/newsletters', {
        params: { header: undefined },
        body: {
          title: data.title,
        },
      })
      return response
    },
    onSuccess: async (response) => {
      setMessage('Newsletter successfully created', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      if (response.data?.id) {
        navigate(`/JJUpdates/Newsletters/${response.data.id}`)
      } else {
        navigate(`/JJUpdates`)
      }
    },
    onError: async () => {
      setMessage('Error creating newsletter', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  const createNewsletterWrapper = handleSubmit((data) => {
    createNewsletter.mutate(data)
  })

  return (
    <Page
      header={
        <PageHeader
          title="Create a New Newsletter"
          backPath="/JJUpdates"
          backText="Newsletters"
          isSecondary={true}
        />
      }
    >
      <form onSubmit={createNewsletterWrapper}>
        <Stack spacing={4}>
          <Box>
            <TextField
              required
              error={isPresent(errors.title)}
              label="Title"
              placeholder="Enter newsletter title"
              {...register('title')}
            />
            {errors.title?.message && (
              <FormFieldErrorMessage message={errors.title.message} />
            )}
          </Box>
          <Stack direction="row" spacing={2}>
            <Button
              type="submit"
              variant="contained"
              disabled={createNewsletter.isPending}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                navigate('/JJUpdates')
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </Page>
  )
}
