import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLayout } from 'hooks/useLayout'
import { Modal } from 'components/Modal/Modal'
import { useSnackbar } from 'stores/useSnackbar'
import { type StateFileUpload } from 'types/fileUpload'
import { usePostPrizeFiles } from 'hooks/api/Settings/usePostPrizeFiles'
import { Box, Button, Typography } from '@mui/material'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { UploaderDisplayer } from 'components/FileUploader/UploaderDisplayer'
import { ActivityButton } from 'components/ActivityButton'

export const UploadPrizeFiles = ({
  isModalOpen,
  toggleModalIsOpen,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void
}) => {
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const [imageFiles, setImageFiles] = useState<File[]>([])
  const [animationFiles, setAnimationFiles] = useState<File[]>([])
  const [showImageFiles, setShowImageFiles] = useState(false)
  const [showAnimationFiles, setShowAnimationFiles] = useState(false)
  const [stateImageFilesUpload, setStateImageFilesUpload] =
    useState<StateFileUpload>(0)
  const [stateAnimationFilesUpload, setStateAnimationFilesUpload] =
    useState<StateFileUpload>(0)
  const [messageError, setMessageError] = useState('')
  const { isMobile } = useLayout()

  const { handleSubmit } = useForm()

  const toggleShowImageFiles = () => setShowImageFiles(!showImageFiles)
  const toggleShowAnimationFiles = () =>
    setShowAnimationFiles(!showAnimationFiles)
  const toggleStateImageFilesUpload = (value: StateFileUpload) => {
    setMessageError('')
    if (stateImageFilesUpload === 0) {
      setStateImageFilesUpload(value)
    }
    if (value === 2) {
      setMessageError('Please correct errors to continue')
    }
  }
  const toggleStateAnimationFilesUpload = (value: StateFileUpload) => {
    setMessageError('')
    if (stateAnimationFilesUpload === 0) {
      setStateAnimationFilesUpload(value)
    }
    if (value === 2) {
      setMessageError('Please correct errors to continue')
    }
  }

  const removeImageFile = (file: File) => {
    setImageFiles(
      imageFiles?.filter((fileMatch) => {
        return fileMatch.name !== file.name
      })
    )
    setStateImageFilesUpload(0)
    toggleShowImageFiles()
  }

  const removeAnimationFile = (file: File) => {
    setAnimationFiles(
      animationFiles?.filter((fileMatch) => {
        return fileMatch.name !== file.name
      })
    )
    setStateAnimationFilesUpload(0)
    toggleShowAnimationFiles()
  }

  const addImageFiles = (files: File[]) => {
    setImageFiles(files)
    toggleShowImageFiles()
  }

  const addAnimationFiles = (files: File[]) => {
    setAnimationFiles(files)
    toggleShowAnimationFiles()
  }

  const resetAll = () => {
    toggleModalIsOpen()
    setShowImageFiles(false)
    setShowAnimationFiles(false)
    setImageFiles([])
    setAnimationFiles([])
    setMessageError('')
  }

  const { mutate: putFileSettingMutation, isPending: isUploadActive } =
    usePostPrizeFiles({
      onSuccess: () => {
        resetAll()
        setSnackbarMessage(`Prize files have been updated`)
      },
      onError: (error, _) => {
        console.log(error)
        setMessageError(error.message)
      },
    })

  const updateRewardWrapper = handleSubmit(async () => {
    if (stateImageFilesUpload !== 1 || stateAnimationFilesUpload !== 1) {
      setMessageError('Please correct errors to continue')
      return
    }

    putFileSettingMutation({
      ImageFile: imageFiles[0],
      AnimationFile: animationFiles[0],
    })
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '60%',
        height: isMobile ? '100%' : '75%',
      }}
    >
      <Box>
        <form onSubmit={updateRewardWrapper}>
          <ModalFullHeader
            toggleIsOpen={toggleModalIsOpen}
            title="Upload Prize Files"
          />
          <ModalFullBody heightModal={isMobile ? '100%' : '75%'}>
            {messageError && (
              <Box className="w-full mb-[3%]">
                <FormFieldErrorMessage
                  message={messageError}
                  variant="contained"
                />
              </Box>
            )}
            <Typography variant="subtitle-2" className="w-full">
              Upload Prize Image
            </Typography>
            <UploaderDisplayer
              files={imageFiles}
              addFiles={addImageFiles}
              removeFile={removeImageFile}
              toggleStateFilesUpload={toggleStateImageFilesUpload}
              showImages={showImageFiles}
              textToShowButton="Click to upload."
              informationFileUpload="Saving will overwrite any existing image."
              maxSizeAllowedUpload={500}
              fileFormatsAllowedUpload="image/svg+xml"
            />
            <Box className="pt-10">
              <Typography variant="subtitle-2" className="w-full">
                Upload Prize Animation
              </Typography>
              <UploaderDisplayer
                files={animationFiles}
                addFiles={addAnimationFiles}
                removeFile={removeAnimationFile}
                toggleStateFilesUpload={toggleStateAnimationFilesUpload}
                showImages={showAnimationFiles}
                textToShowButton="Click to upload."
                informationFileUpload="Saving will overwrite any existing animation."
                maxSizeAllowedUpload={500}
                fileFormatsAllowedUpload="application/json"
              />
            </Box>
          </ModalFullBody>
          <ModalFullFooter>
            <Button onClick={resetAll}>Cancel</Button>
            <ActivityButton
              type="submit"
              variant="contained"
              active={isUploadActive}
              disabled={
                !(imageFiles.length === 1 && animationFiles.length === 1)
              }
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </Box>
    </Modal>
  )
}
