import { isNil } from 'lodash'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { NewslettersDetailsHeader } from './NewslettersDetailsHeader'
import { useGetNewsletterById } from 'hooks/api/Newsletter/useGetNewsletterById'
import { NewslettersDetailsSection } from './NewslettersDetailsSection'
import { NewsletterImageSection } from './NewsletterImageSection'
import { NewsletterFileSection } from './NewsletterFileSection'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'

export const NewslettersDetailsPage = () => {
  const { id: idParam } = useParams()
  const newsletterId = Number(idParam)
  const newsletterQuery = useGetNewsletterById(newsletterId)

  if (newsletterQuery.isPending) {
    return <ActivityIndicator />
  }

  if (newsletterQuery.isError || isNil(newsletterQuery.data)) {
    return <Box>An error occurred fetching the newsletter.</Box>
  }

  const newsletter = newsletterQuery.data

  return (
    <NewslettersDetailsHeader newsletter={newsletter}>
      <Box>
        <NewslettersDetailsSection />
        <NewsletterImageSection newsletter={newsletter} />
        <NewsletterFileSection newsletter={newsletter} />
      </Box>
    </NewslettersDetailsHeader>
  )
}
