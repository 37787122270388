import {
  Alert,
  Button,
  Grid,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { useLayout } from 'hooks/useLayout'
import { Modal } from 'components/Modal/Modal'
import { boolean, object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ActivityButton } from 'components/ActivityButton'
import { isPresent } from '@jjvgaming/player-payback-library'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import {
  FormProvider,
  Controller,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { type EditNewsletterDetailsProps } from './NewslettersDetailsSection'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage/FormFieldErrorMessage'

const StateSelect = () => {
  const {
    control,
    formState: { defaultValues },
  } = useFormContext()

  return (
    <Controller
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox {...field} checked={!!field.value} color="primary" />
          }
          label="Active"
        />
      )}
      name="Newsletter.active"
      control={control}
      defaultValue={defaultValues?.Newsletter.active ?? false}
    />
  )
}

export const EditNewsletterDetails = ({
  newsletter,
  onSubmitNewsletter,
  toggleModalIsOpen,
  updateIsActive,
  isModalOpen,
}: EditNewsletterDetailsProps) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '50%'

  const NewsletterDetailsSchema = object().shape({
    Newsletter: object({
      title: string().required('Must enter a title'),
      active: boolean(),
    }),
  })

  const formMethods = useForm({
    resolver: yupResolver(NewsletterDetailsSchema),
    mode: 'all',
    defaultValues: {
      Newsletter: {
        title: newsletter.title,
        active: newsletter.active ?? false,
      },
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const handleSubmitWrapper = handleSubmit((data) => {
    onSubmitNewsletter({
      ...newsletter,
      title: data.Newsletter.title,
      active: data.Newsletter.active,
    })
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '40%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <ModalFullHeader
            toggleIsOpen={toggleModalIsOpen}
            title={'Edit Newsletter'}
          />
          <ModalFullBody heightModal={heightModal}>
            <Grid container rowSpacing={2}>
              {errors.Newsletter && (
                <Grid item xs={12} pb={4}>
                  <Alert severity="error">
                    Please correct errors to continue
                  </Alert>
                </Grid>
              )}

              <Grid item container alignItems={'center'} xs={12} md={12}>
                <Grid item xs={12} md={6}>
                  <InputLabel required id="name" sx={{ fontWeight: 'normal' }}>
                    Title
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    {...register('Newsletter.title', {
                      required: true,
                    })}
                    error={isPresent(errors.Newsletter?.title)}
                    sx={{ width: '100%' }}
                  />
                  {errors.Newsletter?.title?.message && (
                    <FormFieldErrorMessage
                      message={errors.Newsletter.title.message}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item container alignItems={'center'} xs={12} md={12}>
                <Grid item xs={12} md={6}>
                  <InputLabel
                    sx={{ fontWeight: 'normal' }}
                    id="Newsletter.active"
                  >
                    Status
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StateSelect />

                  {errors.Newsletter?.active?.message && (
                    <FormFieldErrorMessage
                      message={errors.Newsletter.active.message}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={toggleModalIsOpen}>
              Cancel
            </Button>
            <ActivityButton
              active={updateIsActive}
              type="submit"
              variant="contained"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
