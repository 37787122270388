import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useApiClient } from 'hooks/useApiClient'
import { type PrizeFilesRequest } from 'types/fileUpload'

interface UsePostPrizeFilesProps {
  onSuccess?: (data: boolean, variables: PrizeFilesRequest) => void
  onError?: (error: Error, variables: PrizeFilesRequest) => void
}

export const usePostPrizeFiles = ({
  onSuccess,
  onError,
}: UsePostPrizeFilesProps) => {
  const postAPIClient = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (files: PrizeFilesRequest) => {
      const body = new FormData()
      body.set('ImageFileUpload', files.ImageFile)
      body.set('AnimationFileUpload', files.AnimationFile)

      const { data, error } = await postAPIClient.post(
        '/setting/patron-app/prize-files',
        {
          params: {},
          body: {},
          bodySerializer: () => body,
        }
      )
      return { data, error }
    },
    onSuccess: async ({ data, error }, variables: PrizeFilesRequest) => {
      await queryClient.invalidateQueries({
        queryKey: ['/setting/patron-app/prize-image'],
      })
      if (error !== undefined) {
        onError?.(error as Error, variables)
      } else {
        onSuccess?.(data as boolean, variables)
      }
    },
    onError,
  })
}
