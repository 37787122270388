import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetNewsletters = () => {
  const get = useTypedApiClient({
    path: '/admin/newsletters',
    method: 'get',
  })

  return useQuery({
    queryKey: ['/admin/newsletters'],
    queryFn: async () => {
      const { data } = await get({})
      return data
    },
  })
}
