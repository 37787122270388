import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { type Newsletter } from 'types/api'
import { useLayout } from 'hooks/useLayout'
import { Modal } from 'components/Modal/Modal'
import { useSnackbar } from 'stores/useSnackbar'
import { Box, Button, Grid, Typography } from '@mui/material'
import { type StateFileUpload } from 'types/fileUpload'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { SectionHeader } from 'components/SectionHeader'
import { SimpleImageCard } from 'components/ImageCard/SimpleImageCard'
import { usePutImageNewsletter } from 'hooks/api/Newsletter/usePutImageNewsletter'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { UploaderDisplayer } from 'components/FileUploader/UploaderDisplayer'
import { ActivityButton } from 'components/ActivityButton'

export const NewsletterImageSection = ({
  newsletter,
}: {
  newsletter: Newsletter
}) => {
  const { isMobile } = useLayout()
  const [files, setFiles] = useState<File[]>([])
  const [showImages, setShowImages] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const [stateFilesUpload, setStateFilesUpload] = useState<StateFileUpload>(0)

  const toggleModalIsOpen = () => setIsModalOpen(!isModalOpen)
  const { handleSubmit } = useForm()

  const toggleShowImages = () => setShowImages(!showImages)
  const toggleStateFilesUpload = (value: StateFileUpload) => {
    setMessageError('')
    if (stateFilesUpload === 0) {
      setStateFilesUpload(value)
    }
    if (value === 2) {
      setMessageError('Please correct errors to continue')
    }
  }
  const removeFile = (file: File) => {
    setFiles(
      files?.filter((fileMatch) => {
        return fileMatch.name !== file.name
      })
    )
    setStateFilesUpload(0)
    toggleShowImages()
  }

  const addFiles = (files: File[]) => {
    setFiles(files)
    toggleShowImages()
  }

  const resetAll = () => {
    toggleModalIsOpen()
    setShowImages(false)
    setFiles([])
    setMessageError('')
  }

  const { mutate: putImageNewsletterMutation, isPending: isUploadActive } =
    usePutImageNewsletter({
      id: Number(newsletter.id),
      onSuccess: () => {
        resetAll()
        setSnackbarMessage(`${newsletter.title} has been updated`)
      },
      onError: (error, _) => {
        console.log(error)
        setMessageError(error.message)
      },
    })

  const updateNewsletterWrapper = handleSubmit(async () => {
    if (stateFilesUpload !== 1) {
      setMessageError('Please correct errors to continue')
      return
    }

    putImageNewsletterMutation({
      ImageFile: files[0],
      ImageFileName: files[0].name,
      ImageFileType: 'Image',
    })
  })

  return (
    <>
      <SectionHeader
        title="Image"
        onClickButton={toggleModalIsOpen}
        buttonText="Add/Remove Image"
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          rowGap: '16px',
          borderWidth: 1,
          borderColor: 'grey.300',
          borderRadius: 2,
          p: '16px',
          mb: '40px',
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="body-3" className="w-full">
              Thumbnail Image
            </Typography>
            <SimpleImageCard
              imageSource={newsletter.publicImageUrl}
              sx={{ width: '358px' }}
            />
          </Grid>
        </Grid>
      </Box>

      <Modal
        isOpen={isModalOpen}
        toggleIsOpen={() => {}}
        sx={{
          width: isMobile ? '100%' : '50%',
          height: isMobile ? '100%' : '55%',
        }}
      >
        <Box>
          <form onSubmit={updateNewsletterWrapper}>
            <ModalFullHeader
              toggleIsOpen={toggleModalIsOpen}
              title={newsletter.title}
            />
            <ModalFullBody heightModal={isMobile ? '100%' : '55%'}>
              {messageError && (
                <Box className="w-full mb-[3%]">
                  <FormFieldErrorMessage
                    message={messageError}
                    variant="contained"
                  />
                </Box>
              )}
              <Typography variant="subtitle-2" className="w-full">
                Upload Thumbnail Image
              </Typography>
              <UploaderDisplayer
                files={files}
                addFiles={addFiles}
                removeFile={removeFile}
                toggleStateFilesUpload={toggleStateFilesUpload}
                showImages={showImages}
                textToShowButton="Click to upload"
                informationFileUpload="JPEG or PNG (max. 512kb)"
                maxSizeAllowedUpload={500}
                fileFormatsAllowedUpload="image/jpeg, image/jpg, image/png"
              />
            </ModalFullBody>
            <ModalFullFooter>
              <Button onClick={resetAll}>Cancel</Button>
              <ActivityButton
                type="submit"
                variant="contained"
                active={isUploadActive}
              >
                Save
              </ActivityButton>
            </ModalFullFooter>
          </form>
        </Box>
      </Modal>
    </>
  )
}
