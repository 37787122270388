import { isPresent } from '@jjvgaming/player-payback-library'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type Newsletter } from 'types/api'

const path = '/admin/newsletters/{id}'
const method = 'put'

interface UsePutNewsletterParams {
  newsletterId: number
  onSuccess?: () => void
  onError?: () => void
}

type WritableNewsletter = Newsletter & {
  id: number
}

const isNewsletterWritable = (
  newsletter: Newsletter
): newsletter is WritableNewsletter => {
  return isPresent(newsletter.id)
}

export const usePutNewsletter = ({
  newsletterId,
  onSuccess,
  onError,
}: UsePutNewsletterParams) => {
  const queryClient = useQueryClient()
  const put = useTypedApiClient({ path, method })
  const mutate = useMutation({
    mutationKey: ['/admin/newsletters/{id}', newsletterId],
    mutationFn: async (newsletter: Newsletter) => {
      if (isNewsletterWritable(newsletter)) {
        const { data } = await put(newsletter)
        return data
      } else {
        throw new Error('Cannot write Newsletter without required key: id')
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/newsletters', newsletterId],
      })
      onSuccess?.()
    },
    onError,
  })

  return mutate
}
