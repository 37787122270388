import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useApiMutation } from 'hooks/useApiMutation'
import { useSnackbar } from 'stores/useSnackbar'
import { useNavigate } from 'react-router-dom'
import { formatISO } from 'date-fns'
import { isNil } from 'lodash'
import { type CampaignSchema } from 'pages/Communications/CommunicationsNew/Message/CampaignSchema'

export const usePostCampaign = () => {
  const post = useTypedApiClient({
    path: '/admin/campaigns',
    method: 'post',
  })
  const setMessage = useSnackbar((state) => state.setMessage)
  const navigate = useNavigate()

  return useApiMutation<typeof post, typeof CampaignSchema>({
    mutationFn: async (formData) => {
      const {
        typeOfMessage,
        scheduledDate,
        licensedEstablishments,
        contestId,
        excludeType,
        excludedId,
        subjectLine,
        messageContent,
        corporateAccountId,
        tierId,
      } = formData
      if (isNil(scheduledDate)) {
        throw Error('Scheduled date is required')
      }
      return await post({
        licensedEstablishments:
          typeOfMessage === 'Location' ? licensedEstablishments : undefined,
        contestId: typeOfMessage === 'Sweepstakes' ? contestId : undefined,
        corporateAccountId:
          typeOfMessage === 'Corporate' ? corporateAccountId : undefined,
        excludedCorporateAccountId:
          typeOfMessage === 'AllPatrons' && excludeType === 'CA'
            ? excludedId
            : undefined,
        excludedOrganizationId:
          typeOfMessage === 'AllPatrons' && excludeType === 'Org'
            ? excludedId
            : undefined,
        excludedLicensedEstablishmentId:
          typeOfMessage === 'AllPatrons' && excludeType === 'LE'
            ? excludedId
            : undefined,
        excludePatronsWithCheckins: !!(
          typeOfMessage === 'AllPatrons' &&
          excludeType === 'excludePatronsWithCheckins'
        ),
        tierId: typeOfMessage === 'AllPatronsByTier' ? tierId : undefined,
        subject: subjectLine,
        body: messageContent,
        deliveryDate: formatISO(scheduledDate),
        type: typeOfMessage,
      })
    },
    onError: (error) => {
      alert(error.data.message)
    },
    onSuccess: () => {
      setMessage('Message created successfully', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      navigate('/Communications')
    },
  })
}
