import { useLayout } from 'hooks/useLayout'
import { number, object, boolean } from 'yup'
import { useEffect, useCallback } from 'react'
import { Modal } from 'components/Modal/Modal'
import { useSnackbar } from 'stores/useSnackbar'
import { colorPrimitives } from 'components/Theme'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { TextField } from 'components/Shared/TextField'
import type { components } from 'api/playerPayback/api'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { isPresent } from '@jjvgaming/player-payback-library'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { ActivityButton } from 'components/ActivityButton'
import { WelcomeBonusPointSwitch } from './WelcomeBonus/WelcomeBonusPointSwitch'

interface BonusPointSettingsForm {
  enablePatronWelcomeBonus: boolean
  patronWelcomeBonus: number
}

const BonusPointSettingsSchema = object({
  enablePatronWelcomeBonus: boolean().required(
    'Enable Patron Welcome Point Bonus is required.'
  ),
  patronWelcomeBonus: number()
    .typeError('Welcome Point Bonus value must be a number.')
    .test(
      'is-positive-or-zero',
      'Value must be a positive number or zero',
      (value) => {
        return value === 0 || (typeof value === 'number' && value > 0)
      }
    )
    .required('Welcome Point Bonus value is required.'),
})

export const BonusPointSettingsModal = ({
  checkInPolicy,
  isModalVisible,
  toggleModalVisibility,
}: {
  checkInPolicy: components['schemas']['FullCheckInPolicyDTO']
  isModalVisible: boolean
  toggleModalVisibility: () => void
}) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '75%'
  const setMessage = useSnackbar((state) => state.setMessage)
  const queryClient = useQueryClient()

  const formMethods = useForm<BonusPointSettingsForm>({
    resolver: yupResolver(BonusPointSettingsSchema),
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    reset,
  } = formMethods

  const resetForm = useCallback(() => {
    reset({
      enablePatronWelcomeBonus: Boolean(
        checkInPolicy?.enablePatronWelcomeBonus
      ),
      patronWelcomeBonus: Number(checkInPolicy?.patronWelcomeBonus),
    })
  }, [checkInPolicy])

  useEffect(() => {
    resetForm()
  }, [resetForm])

  const enablePatronPointBonus = watch('enablePatronWelcomeBonus')

  const closeModal = () => {
    toggleModalVisibility()
    resetForm()
  }

  const putBonusPoints = useTypedApiClient({
    path: '/admin/policies/checkins/{id}',
    method: 'put',
  })

  const updateBonusPointsMutation = useMutation({
    mutationFn: async ({
      enablePatronWelcomeBonus,
      patronWelcomeBonus,
    }: BonusPointSettingsForm) => {
      if (checkInPolicy?.id && checkInPolicy?.rewardsProgramId) {
        checkInPolicy.enablePatronWelcomeBonus = enablePatronWelcomeBonus
        checkInPolicy.patronWelcomeBonus = enablePatronWelcomeBonus
          ? patronWelcomeBonus
          : 0
        return await putBonusPoints(checkInPolicy)
      }
    },
  })

  useEffect(() => {
    if (updateBonusPointsMutation.isSuccess) {
      queryClient
        .invalidateQueries({
          queryKey: [
            '/admin/policies/checkins/rewards-programs',
            checkInPolicy.rewardsProgramId,
          ],
        })
        .finally(() => {})
      setMessage('Bonus Points have been updated.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      closeModal()
    } else {
      const error: any = updateBonusPointsMutation.error as Error
      if (error?.message) {
        setMessage(error?.message, 'error', {
          vertical: 'top',
          horizontal: 'right',
        })
      }
    }
  }, [updateBonusPointsMutation.isSuccess, updateBonusPointsMutation.isError])

  const updateBonusPoints = handleSubmit((data) => {
    updateBonusPointsMutation.mutate(data)
  })

  return (
    <Modal
      isOpen={isModalVisible}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '70%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={updateBonusPoints}>
          <ModalFullHeader
            title="Bonus Points"
            subTitle={checkInPolicy.rewardsProgram?.name}
            toggleIsOpen={closeModal}
          />
          <ModalFullBody heightModal={heightModal}>
            <Stack spacing={5}>
              <WelcomeBonusPointSwitch />
              {enablePatronPointBonus && (
                <Box>
                  <Box className="flex flex-col sm:flex-row justify-stretch w-full px-1">
                    <Box className="w-full sm:w-1/2 sm:pt-4 flex">
                      <Typography># of Points</Typography>
                      <Typography color={colorPrimitives.redGaming}>
                        *
                      </Typography>
                    </Box>
                    <Box className="flex flex-row gap-6 w-full sm:w-1/2">
                      <TextField
                        placeholder="Points"
                        {...register('patronWelcomeBonus', { required: true })}
                        error={isPresent(errors.patronWelcomeBonus)}
                        sx={{ width: '100px' }}
                        bottomContent={
                          errors.patronWelcomeBonus?.message && (
                            <FormFieldErrorMessage
                              message={errors.patronWelcomeBonus.message}
                            />
                          )
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Stack>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={closeModal}>
              Cancel
            </Button>
            <ActivityButton
              active={updateBonusPointsMutation.isPending}
              variant="contained"
              type="submit"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
